// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow: auto;
  max-height: 70vh;

  thead {
    th {
      white-space: nowrap;
    }
  }
  tbody {
    tr:first-of-type {
      th:first-of-type {
        padding: 0;
        background-color: red !important;
      }
    }
    th {
      padding-top: 0;
      padding-bottom: 0;
      background-color: $input-solid-bg-dark;
      border-right: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
      border-top: 0;
      transition: background-color 0.2s ease;

      .filter-label {
        display: block;
        width: 100%;
        margin-bottom: 0;

        .text-filter {
          border: none;
          padding: 0;
          background-color: $input-solid-bg-dark;
          transition: background-color 0.2s ease;
        }
      }

      &:hover {
        background-color: $white;

        .text-filter {
          background-color: $white;
        }
      }
    }
    td {
      &.react-bootstrap-table-editing-cell {
        padding: 0;

        .form-control {
          margin: 0;
          border-radius: 3px;
        }
      }
    }
  }
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}


// ----------------------------------------
// Custom CSS
// ----------------------------------------
.btn.btn-icon .svg-icon {
  display: flex;
}

.form-check {
  input[type=radio],
  input[type=checkbox] {
    margin-top: 0.15rem;
  }
  .form-check-label {
    margin-left:0.2rem;
  }
}

.rowpulse > * {
  animation: rowpulse 3s;
}

@keyframes rowpulse {
  50% {
    background-color: #15ab1b38;
  }
}

.table-clear {
  cursor: pointer;
  width: 100%;
  border-radius: 0;
}

.compact-view {
  .react-bootstrap-table {
    font-size: 11px;

    // Header
    thead {
      th {
        padding: 0.8rem 0.1rem 0.6rem 0.6rem !important;
      }
    }
    .filter-label {
      input {
        height: 2.5rem !important;
      }
    }
    .table-clear {
      height: 2.5rem !important;
      line-height: 1rem;
    }

    // Body
    .td-wrapper {
      padding: 0.1rem !important;
    }

    // Actions
    .btn-sm {
      height: 2.1rem;
      width: 2.1rem;

      svg {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
}

.table-sticky-1 {
  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr th:first-of-type {
      position: sticky;
      left: 0;
    }
  }
  tbody {
    tr td:first-of-type,
    tr th:first-of-type {
      position: sticky;
      left: 0;
      background-color: #fff;
      border-left: 1px solid $table-border-color;
    }
  }
}
